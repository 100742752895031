@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*, ::after, ::before {
  box-sizing: border-box;
}

body {
  background-color: white;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Montserrat";
  margin: 0px !important;
  font-optical-sizing: auto;
}

span, label {
  font-family: "Montserrat";
  font-optical-sizing: auto;
}

img, svg {
  vertical-align: middle;
}

a {
  text-transform: none;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
}

li, p, a, input[type="text"],
input[type="email"] {
  font-family: "Poppins";
  font-optical-sizing: auto;
}

.d-grid {
  display: grid;
}

.content-full {
  button {
    width: 100%;
    align-items: center;
    justify-content: center;
    .p-button-label {
      flex: unset;
    }
  }
}

.invalid-otp {
  input {
    border-color: red;
    &:focus {
      box-shadow: 0 0 0 0.2rem #ff000052;
    }
  }
}

router-outlet + * {
     margin-top: 10px;
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 10px;
}

.domain_check_notice {
  .p-element {
    .p-message {
      margin: 0px 0px 5px 0px;
    }
    .p-message-wrapper {
      padding: 10px 20px;
      .p-message-summary {
        font-size: 14px;
      }
      .p-message-icon, .p-message-close {
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
}
